import * as ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import { initializeGTM } from '@bootstrap/utils/gtm';
import { initializeMixpanel } from '@bootstrap/utils/mixpanel';
import { initializeSentry } from '@bootstrap/utils/sentry';

import { router } from './app/AppRouter';
import config from './configs/constants';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

initializeSentry(config.SENTRY_DSN);
initializeMixpanel(config.MIXPANEL_ID);
initializeGTM({ gtmId: config.GTM_CODE, auth: config.GTM_AUTH, preview: config.GTM_PREVIEW });

root.render(<RouterProvider router={router} />);
