import { lazy } from 'react';
import ReactBreakpoints from 'react-breakpoints';
import { createBrowserRouter } from 'react-router-dom';

import { Logout } from '@bootstrap/components/Logout';
import { NotFoundPage } from '@bootstrap/components/NotFoundPage';
import breakpoints from '@bootstrap/configs/breakpoints';
import CollectiveInvoiceDetails from '@connect/modules/collective-invoice/CollectiveInvoiceDetails';
import { RoutePath } from '@connect/shared/constants';
import { Offset } from '@ui/offset';

import { ConnectApp } from './ConnectApp';

const Accounts = lazy(() => import('../pages/AccountsPage'));
const AccountsInvitations = lazy(() => import('../pages/AccountsInvitationsPage'));
const AccountsOverview = lazy(() => import('../pages/AccountsOverviewPage'));
const BillingRequestDetail = lazy(
  () => import('../modules/billing-request/billing-request-detail/BillingRequestDetail'),
);
const BillingRequestMobileEditor = lazy(
  () => import('../modules/billing-request/billing-request-editor/mobile-editor/BillingRequestMobileCreateForm'),
);
const BillingRequests = lazy(() => import('../modules/billing-requests/BillingRequests'));
const MerchantBulkInvitation = lazy(
  () => import('@connect/modules/merchant/merchant-bulk-invitation/MerchantBulkInvitation'),
);
const ContractDetails = lazy(() => import('../pages/ContractDetailsPage'));
const Contracts = lazy(() => import('../pages/ContractsPage'));
const ContractTemplateDetails = lazy(() => import('../modules/contract-template/ContractTemplateDetails'));
const ContractTemplates = lazy(() => import('../modules/contract-template/ContractTemplates'));
const CreditNoteMobileEditor = lazy(() => import('../modules/credit-note/mobile-editor/CreditNoteMobileEditor'));
const Dashboard = lazy(() => import('../modules/dashboard/Dashboard'));

const CollectiveInvoices = lazy(() => import('../pages/CollectiveInvoicesPage'));
const CollectiveInvoicesOverview = lazy(() => import('../pages/CollectiveInvoicesOverviewPage'));
const CollectiveInvoicesUpcoming = lazy(() => import('../pages/CollectiveInvoicesUpcomingPage'));
const CollectiveInvoiceDetailsMerchant = lazy(
  () => import('../modules/collective-invoice/CollectiveInvoiceDetailsMerchant'),
);

const InvoiceDetails = lazy(() => import('../modules/invoice/invoice-details/InvoiceDetails'));
const Invoices = lazy(() => import('../pages/InvoicesPage'));
const SalesInvoices = lazy(() => import('../pages/SalesInvoicesPage'));
const PurchaseInvoices = lazy(() => import('../pages/PurchaseInvoicesPage'));

const MerchantDetails = lazy(() => import('../modules/merchant/merchant-details/MerchantDetails'));
const MerchantProfile = lazy(() => import('../modules/profile/merchant-profile/MerchantProfile'));
const OnboardingTemplates = lazy(() => import('../modules/onboarding-templates'));
const Organizations = lazy(() => import('../modules/organizations'));
const DebtorProfile = lazy(() => import('../modules/profile/debtor-profile/Profile'));
const Project = lazy(() => import('../modules/project/Project'));
const Projects = lazy(() => import('../modules/projects/Projects'));
const TransactionStatements = lazy(() => import('../pages/TransactionStatementsPage'));
const TransactionStatementsOverview = lazy(() => import('../pages/TransactionStatementsOverviewPage'));
const TransactionStatementsArchive = lazy(() => import('../pages/TransactionStatementsArchivePage'));
const TransactionStatementDetails = lazy(() => import('../pages/TransactionStatementDetailsPage'));
const TransferStatements = lazy(() => import('../pages/TransferStatementsPage'));
const TransferStatementDetails = lazy(() => import('../pages/TransferStatementDetailsPage'));
const Users = lazy(() => import('../modules/users'));
const UserDetail = lazy(() => import('../modules/users/components/user-detail/UserDetails'));

const routes = [
  {
    element: (
      <ReactBreakpoints breakpoints={breakpoints}>
        <ConnectApp />
      </ReactBreakpoints>
    ),
    children: [
      { path: '/', element: <Dashboard /> },
      {
        path: RoutePath.BillingRequests,
        children: [
          { index: true, element: <BillingRequests /> },
          { path: ':id', element: <BillingRequestDetail /> },
          { path: 'new', element: <BillingRequestMobileEditor /> },
        ],
      },
      {
        path: RoutePath.Invoices,
        element: <Invoices />,
        children: [
          { index: true, element: <SalesInvoices /> },
          { path: RoutePath.PurchaseInvoices, element: <PurchaseInvoices /> },
        ],
      },
      { path: `${RoutePath.Invoices}/:id`, element: <InvoiceDetails /> },
      { path: RoutePath.AccountProfile, element: <MerchantProfile /> },
      {
        path: RoutePath.TransactionStatements,
        element: <TransactionStatements />,
        children: [
          { index: true, element: <TransactionStatementsOverview /> },
          { path: 'archived', element: <TransactionStatementsArchive /> },
        ],
      },
      { path: `${RoutePath.TransactionStatements}/:id/*`, element: <TransactionStatementDetails /> },
      { path: `${RoutePath.TransactionStatementsArchived}/:id`, element: <CollectiveInvoiceDetailsMerchant /> },
      {
        path: RoutePath.CollectiveInvoices,
        element: <CollectiveInvoices />,
        children: [
          { index: true, element: <CollectiveInvoicesOverview /> },
          { path: 'upcoming', element: <CollectiveInvoicesUpcoming /> },
        ],
      },
      { path: `${RoutePath.CollectiveInvoices}/:id`, element: <CollectiveInvoiceDetails /> },
      {
        path: RoutePath.Contracts,
        children: [
          { index: true, element: <Contracts /> },
          { path: ':id', element: <ContractDetails /> },
        ],
      },
      {
        path: RoutePath.ContractTemplates,
        children: [
          { index: true, element: <ContractTemplates /> },
          { path: ':id', element: <ContractTemplateDetails /> },
          { path: 'new', element: <ContractTemplateDetails /> },
        ],
      },
      { path: RoutePath.CreditInvoice, element: <CreditNoteMobileEditor /> },
      {
        path: RoutePath.Projects,
        children: [
          {
            index: true,
            element: <Projects />,
          },
          { path: ':id', element: <Project /> },
        ],
      },
      { path: RoutePath.OnboardingTemplates, element: <OnboardingTemplates /> },
      {
        path: RoutePath.Accounts,
        element: <Accounts />,
        children: [
          { index: true, element: <AccountsOverview /> },
          { path: RoutePath.AccountsInvitations, element: <AccountsInvitations /> },
        ],
      },
      { path: `${RoutePath.Accounts}/:id`, element: <MerchantDetails /> },
      { path: `${RoutePath.Accounts}/new`, element: <MerchantBulkInvitation /> },
      {
        path: RoutePath.TransferStatements,
        children: [
          { index: true, element: <TransferStatements /> },
          { path: ':id', element: <TransferStatementDetails /> },
        ],
      },
      {
        path: RoutePath.Settings,
        children: [
          {
            path: 'users',
            children: [
              { index: true, element: <Users /> },
              { path: ':id', element: <UserDetail /> },
            ],
          },
          { path: 'profile', element: <DebtorProfile /> },
          { path: 'organizations', element: <Organizations /> },
        ],
      },
      { path: RoutePath.Logout, element: <Logout /> },
      {
        path: '*',
        element: (
          <Offset offset={24}>
            <NotFoundPage />
          </Offset>
        ),
      },
    ],
  },
];

export const router = createBrowserRouter(routes);
